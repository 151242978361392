<template>
    <span class="update-status" :class="status">
        <div class="indicator"></div>
        <span
            class="mobile status-text"
            :class="{ title: showLabel == false }"
            v-show="showLabel"
        >
            {{ label }}
        </span>
        <span>
            <img
                class="icon-pass mobile"
                v-if="mapp === 'i_rwbpkg' && mes === '1'"
                :src="imageBaseURL + 'Images/picto/optpassact.png'"
                width="18"
            />
        </span>
    </span>
</template>

<script>
import { updateStatus } from "@/enums"

export default {
    name: "UpdateStatus",
    props: {
        showLabel: {
            type: Boolean,
            default: true,
        },
        status: {
            type: String,
            default: null,
        },
        rw: {
            type: String,
            default: null,
        },
        lite: {
            type: String,
            default: null,
        },
        mapp: {
            type: String,
            default: null,
        },
        mes: {
            type: String,
            default: null,
        },
        from: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            updateStatus,
            imageBaseURL: process.env.VUE_APP_IMAGES_URL,
        }
    },
    computed: {
        label() {
            let statusLabel = null
            if (this.from === "residence") {
                switch (this.status) {
                    case updateStatus.OK:
                        statusLabel = this.$t("global.status.ok")
                        break
                    case updateStatus.INPROGRESS:
                        statusLabel = this.$t("global.status.inProgress")
                        break
                    case updateStatus.NEEDUPDATE:
                        statusLabel = this.$t("global.status.needUpdate")
                        break
                    case updateStatus.ERROR:
                        statusLabel = this.$t("global.status.error")
                        break
                }
            } else if (this.from === "central") {
                if (this.rw === "0" && this.lite === "0") {
                    switch (this.status) {
                        case updateStatus.OK:
                            statusLabel = this.$t("global.status.ok")
                            break
                        case updateStatus.INPROGRESS:
                            statusLabel = this.$t("global.status.inProgress")
                            break
                        case updateStatus.NEEDUPDATE:
                            statusLabel = this.$t("global.status.needUpdate")
                            break
                        case updateStatus.ERROR:
                            statusLabel = this.$t("global.status.error")
                            break
                    }
                }
            }
            return statusLabel
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

.update-status {
    display: flex;
    align-items: center;
    font-size: $small;
    position: relative;

    .indicator {
        width: 18px;
        height: 18px;
        border-radius: 5px;
    }

    .status-text {
        margin-left: 0.5em;
    }

    &:hover {
        .title.mobile {
            display: block !important;
            position: absolute;
            z-index: 1;
            width: 165px;
            text-align: center;
            background-color: Gainsboro;
            margin: 0 0 0 23px;
            border-radius: 5px;
            box-shadow: 1px 1px 3px DarkSlateBlue;
        }
    }
}

.ok {
    .indicator {
        background-color: $status-green;
    }
}
.inprogress {
    .indicator {
        background-color: $status-orange;
    }
}
.needupdate {
    .indicator {
        background-color: $status-red;
    }
}
.error {
    .indicator {
        background-color: $status-black;
    }
}

.icon-pass {
    margin-left: 10px;
}
</style>
